/* jshint ignore:start */


var isSafari = navigator.userAgent.indexOf("Safari") > -1;
var isChrome = navigator.userAgent.indexOf('Chrome') > -1;
if ((isChrome) && (isSafari)) {
    isSafari = false;
}
var msie = navigator.userAgent.indexOf("MSIE ");
var msie11 = navigator.userAgent.match(/Trident\/7\./);

function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0) {
            return null;
        }
    } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end === -1) {
            end = dc.length;
        }
    }
    return unescape(dc.substring(begin + prefix.length));
}

var acc = document.getElementsByClassName("accordion");
    var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }


(function ($) {

    $(document).ready(function () {

        
        function scrollToElement(element,hashscroll) {

            var offset = 65;

            if($(window).width() < 1240 && element.attr('id') == 'kampagnenstory') {
                offset = 65 + 320;
            }

            if($(window).width() < 769 && element.attr('id') == 'kampagnenstory') {
                offset = 65 + 230;
            }

            if(hashscroll) offset += 100;

            if(element.attr('id') == 'cta-mitmachaktion') {
                offset = 0;
            }

            setTimeout(function() {
                $('html, body').animate({scrollTop: element.offset().top - offset}, 500);
            }, 350);

            setTimeout(function() {
                $(document.body).removeClass('scroll-up');
                $(document.body).addClass('scroll-down');
            }, 500);
        }

// Init






// Menü

        // document.getElementById('menu-button').addEventListener('click', (function () {
        //     document.getElementById('menu').classList.toggle('overlay--visible');
        //     document.getElementsByTagName('body')[0].classList.toggle('overlay-menue');
        //      if (!$('.questionnaire').hasClass("overlay--visible") && !$('.checklist').hasClass("overlay--visible")) {
        //         document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
        //      }
        //     // else{
        //     //     document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
        //     // }
        // }));




// Events




// Menü Button Overlay


        $(document).on('click', '.mobile-menu-icon', function (event) {
            $(".overlay-menu").toggleClass("active");
        });


// Anchor Scrolling / Hash Logic

        if (window.location.hash) {
            // $('html, body').animate({
            //     scrollTop: $('a' + window.location.hash).offset().top
            // }, 100);
            //$('body').scrollTo('a' + window.location.hash);
        }

        $(document).on('click', 'p a', function (event) {

            if($(this).attr('href').indexOf('#') && $('#'+$(this).attr('href').split("#")[1]).length) {
                scrollToElement($('#'+$(this).attr('href').split("#")[1]));
                event.preventDefault();
            }

        });





// For formular row click callback
        function isElementInViewport(el) {

            //special bonus for those using jQuery
            if (typeof jQuery === 'function' && el instanceof jQuery) {
                el = el[0];
            }

            var rect = el.getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function createInputClickCallback() {
            return function() {
                // Check if field ist still in view after mobile keyboard is displayed
                var el = this;
                setTimeout(function() {
                    if (!isElementInViewport(el))
                    {
                        el.scrollIntoView();
                    }
                }, 500);
            }
        }

        var textInputs = document.querySelectorAll('input[type=text]');
        for (var i = 0; i < textInputs.length; i++)
        {
            textInputs[i].addEventListener('click', createInputClickCallback());
        }

        // Same for textarea input
        $(document).on('click', '.input contact__form__textarea', createInputClickCallback());



// AJAX checklist INIT







// Exit Disclaimer


        triggerED = function(href,target) {
            vars.exit_link = href;
            vars.exit_link_target = target;
            $('.overlay--exitdisclaimer').addClass("active");
        };

        $(document).on('click', 'a[href*="http"]:not(.brightcove-video)', function (event) {

            var href = $(this).attr('href');
            var target = $(this).attr('target');

            if(isExitDisclaimberLink($(this))) {
                event.preventDefault();
                event.stopImmediatePropagation();
                triggerED(href,target);
            }

        });

        $(document).on('click', '.overlay--exitdisclaimer-gotolink', function () {
            $('.overlay--exitdisclaimer').removeClass("active");
            setTimeout(function() {
                window.open(vars.exit_link, (vars.exit_link_target ? vars.exit_link : '_self'));
            },200);
        });

        $(document).on('click', '.abbvie-layer__close', function () {
            $('.overlay--exitdisclaimer').removeClass("active");
           
        });


//Initialize overlay buttons:

    });

    $(document).on('click', '.clip-clap-trigger', function (event) {
        $(this).closest(".clip-clap-outer").find(".clip-clap").addClass("extended");
        $(this).addClass("faded");
    });

    $(document).on('click', '.dropdown-toggle', function (event) {
        $(this).closest(".dropdown").toggleClass("extended");
    });

 

})(jQuery); // Fully reference jQuery after this point.



/* jshint ignore:end */